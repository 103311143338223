import React from 'react';

class Input extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span className="input">
        <input placeholder=' ' required type={this.props.type ? this.props.type : "text"} name={this.props.name}  />
        <label htmlFor={this.props.name}>{this.props.placeholder}</label>
        <div>
          <hr aria-hidden="true" className='hr-one' />
          <hr aria-hidden="true" className='hr-two' />
        </div>
      </span>
    );
  }
}

export default Input

import React, {useEffect, useRef} from "react";
import Logo from "../atom/Logo";

const Navbar = ({className}) => {
  const contactButton = useRef(null);

  useEffect(() => {
    setInterval(() => {
      console.log("yo", contactButton.current);
      contactButton.current?.classList.add("bounce-left");
      setTimeout(function () {
        contactButton.current?.classList.remove("bounce-left");
      }, 1100);
    }, 5000);
  });

  return (
    <header className={`nav padding ${className}`}>
      <span className="nav__logo">
        <Logo />
      </span>

      <div className="nav__menu">
        <ul className="nav__links">
          {/*<li><Link to="">Projekte</Link></li>
            <li><Link to="">Über uns</Link></li>*/}
          <li>
            <a className="bounce-button" ref={contactButton} href="#contact-us">
              <b>Projektanfrage</b>
              <div className={"nav__bubble"}>
                <p>➜</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;

import React from 'react';
import Input from '../atom/Input';


const FORM_ENDPOINT = "https://public.herotofu.com/v1/05d1c8e0-1bf7-11ed-9730-af3c511c5e41"

function ContactForm() {
  const [status, setStatus] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const injectedData = {
      DYNAMIC_DATA_EXAMPLE: 123,
    };
    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    Object.assign(data, injectedData);

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // It's likely a spam/bot request, so bypass it to validate via captcha
        if (response.status === 422) {
          Object.keys(injectedData).forEach((key) => {
            const el = document.createElement("input");
            el.type = "hidden";
            el.name = key;
            el.value = injectedData[key];

            e.target.appendChild(el);
          });

          e.target.submit();
          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(() => setStatus("We'll be in touch soon."))
      .catch((err) => setStatus(err.toString()));
  };

  return (
      <div className="contact-form__wrapper margin" id="contact-us">
        <div className="contact-form__header">
          {status ?
            <h2>Vielen Dank! <i>wir melden uns!</i></h2>
            :
            <h2><i>Lass uns</i> in Kontakt bleiben</h2>
          }
        </div>

        <div className='contact-form'>
          <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank">

            <div className="contact-form__row">
              <Input type="text" placeholder="Wie heißen Sie?" name="name" />
              <Input type="email" placeholder="Ihre E-Mail-Adresse" name="email" />
            </div>

            <Input type="richtext" placeholder="Was können wir für Sie tun?" name="message" />

            <div className={`input ${status ? "slide-out-right" : ""}`}>
              <input type="submit" name='submit' value={"Senden"}  />
            </div>
          </form>

          <div className="contact-form__info">
            <h4>kontakt@fallerjansen.de</h4>
            <p>Treten wir in Kontakt! Wir stehen Ihnen gerne zur Verfügung.</p>
          </div>

        </div>
      </div>
  );
}

export default ContactForm;

import React from 'react';

let i = 0, offset = 0, skip_count = 0;

class TextQuote extends React.Component {

  constructor(props) {
    super(props);

    const words = this.props.words ?? [
      'wir helfen startups und <b>digital agenturen</b> ihre projekte bestmöglich zu realisieren.',
      'we help startups and <b>digital agencies</b> launch projects on time, with no pain'
    ];
    const len = words.length;

    this.state = {
      isInViewport: false,
      words: words,
      settings: {
        part: "",
        len: len,
        forwards: true,
        skip_delay: 50,
        speed: this.props.speed ?? 40,
      }
    }
    this.typerBox = React.createRef();
    this.typeText = this.typeText.bind(this);
  }

  initObserver() {
    const observer = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting){
        this.initWordFlick()
      }
    });
    observer.observe( this.typerBox.current );
  }

  componentDidMount() {
    this.initObserver();
  }

  initWordFlick() {
    setInterval(this.typeText, this.state.settings.speed);
  }

  typeText() {
    let part;
    if (this.state.settings.forwards) {
      if (offset >= this.state.words[i].length) {
        ++skip_count;
        if (skip_count === this.state.settings.skip_delay) {
          this.setState({
            settings: {forwards: false}
          })
          // this.state.settings.forwards = false;
          skip_count = 0;
        }
      }
    } else {
      if (offset === 0) {
        this.setState({
          settings: {forwards: true}
        })
        // this.state.settings.forwards = true;
        i++;
        offset = 0;
        if (i >= this.state.settings.len) {
          i = 0;
          // this.initWordFlick();
        }
      }
    }
    part = this.state.words[i].substr(0, offset);
    if (skip_count === 0) {
      if (this.state.settings.forwards) {
        offset++;
      } else {
        offset--;
      }
    }

    this.setState({
      part: part
    })
  }

  render() {
    return (
        <div className="slogan__wrapper margin">
          <h1 className="slogan slogan__main" ref={this.typerBox} dangerouslySetInnerHTML={{__html: this.state.part}}/>
        </div>
    );
  }
}

export default TextQuote

import React from 'react';
import Navbar from '../components/element/Navbar';
import Footer from '../components/module/Footer';

import projects from '../data/projects.json';
import { useParams } from 'react-router-dom';

function ReferencePage(props) {
  const {project} = useParams();
  const data = projects[project];

  if (!data) return (<p>Project not found</p>);

  return (
      <div className="HomePage">
        <div className="App">
          <Navbar/>
          <div className="main">
            <div className="detail">
              <div className="content">
                <div className="content__inner--detail">
                  <div className="detail__container">

                    <div className={'detail__hero'}>
                      <video width="100%" height="auto" controls className={'detail__hero-media'}>
                        <source src={data.heroAsset} type="video/mp4"/>
                      </video>
                    </div>

                    <div className="detail-bar__container">
                      <div className="detail__header">
                        <div className="detail__row">
                          <div className="detail__col">
                            <h1>Über das Projekt</h1>
                            <p>{data.description}</p>
                            <a href={data.projectUrl}>{data.projectUrl}</a>
                          </div>
                          <div className="detail__col">
                            <div className="detail__row">
                              <div className="detail__info">
                                <h4>Client</h4>
                                <p>{data.client}</p>
                              </div>
                              <div className="detail__info">
                                <h4>Agency</h4>
                                <p>{data.agency}</p>
                              </div>
                            </div>
                            <div className="detail__row">
                              <div className="detail__info">
                                <h4>Role</h4>
                                <p>{data.role}</p>
                              </div>
                              <div className="detail__info">
                                <h4>Photos</h4>
                                <p>{data.photos}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {data.screens &&
                          <div className="detail__media">
                            <h2 className={'section__headline'}>{data.screensHeader}<sup>{data.screensHeaderUpper}</sup>
                            </h2>
                            <div className="detail__screens">
                              <img src={data.screens[0]} alt={'oneOfThree'}/>
                              <img src={data.screens[1]} alt={'twoOfThree'}/>
                              <img src={data.screens[2]} alt={'threeOfThree'}/>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              {/*{data.sliderAssets &&*/}
              {/*    <div className="section">*/}
              {/*      <h2 className={'section__headline'}>{data.sliderHeader}<sup>{data.sliderHeaderUpper}</sup></h2>*/}

              {/*      <div className="reel__container reel__container--round reel__detail">*/}
              {/*  <span className={'reel__arrow reel__arrow--left'}>*/}
              {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g*/}
              {/*        id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect id="Rechteck_26"*/}
              {/*                                                                                     data-name="Rechteck 26"*/}
              {/*                                                                                     className="cls-1"*/}
              {/*                                                                                     x="-2.69" y="9.31"*/}
              {/*                                                                                     width="28" height="4"*/}
              {/*                                                                                     transform="translate(-4.69 11.31) rotate(-45)"/><rect*/}
              {/*        id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"*/}
              {/*        height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>*/}
              {/*  </span>*/}

              {/*        <div className={'reel__object'}>*/}
              {/*          <div className={`reel__no-slider`}>*/}
              {/*            {data.sliderAssets.map((path, i) => {*/}
              {/*              // return (*/}
              {/*              //     <ReelCard key={i} isAssetOnly={true} assetPath={path} isModalModeActive={true}*/}
              {/*              //               additonal/>*/}
              {/*              // );*/}
              {/*            })}*/}
              {/*          </div>*/}
              {/*        </div>*/}

              {/*        <span className={'reel__arrow reel__arrow--right'}>*/}
              {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g*/}
              {/*        id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect id="Rechteck_26"*/}
              {/*                                                                                     data-name="Rechteck 26"*/}
              {/*                                                                                     className="cls-1"*/}
              {/*                                                                                     x="-2.69" y="9.31"*/}
              {/*                                                                                     width="28" height="4"*/}
              {/*                                                                                     transform="translate(-4.69 11.31) rotate(-45)"/><rect*/}
              {/*        id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"*/}
              {/*        height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>*/}
              {/*  </span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*}*/}
              <div className="detail__quote">
                <i>“</i>
                <p>
                  {data.quote}
                </p>
                <i>„</i>
              </div>
            </div>

            <Footer/>
          </div>
        </div>
      </div>
  );
}

export default ReferencePage;

import React from 'react';
import './App.sass';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePageShort';
import { Helmet } from 'react-helmet';
import ReferencePage from './pages/ReferencePage';
import Impressum from './pages/Impressum';
import Datenschutzerklaerung from './pages/Datenschutzerklaerung';

function App() {
  return (
      <Router>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>Faller & Jansen - Visual Communication</title>
          <meta name="description"
                content="Wir helfen Startups und Digital Agenturen ihre Projekte bestmöglich zu realisieren."/>
        </Helmet>
        <Routes>
          <Route path={'/'} element={<HomePage/>}/>
          <Route path={'/project/:project'} element={<ReferencePage/>}/>
          <Route path={'/impressum'} element={<Impressum/>}/>
          <Route path={'/datenschutzerklaerung'} element={<Datenschutzerklaerung/>}/>
        </Routes>
      </Router>
  );
}

export default App;

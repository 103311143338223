'use client';

import React, { useState } from 'react';
import Slider from 'react-slick';

const PrevButton = (props) => (
    <button className="portfolio-slider__nav portfolio-slider__nav--prev" onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6">
        <defs></defs>
        <g
            id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <rect id="Rechteck_26"
                  data-name="Rechteck 26"
                  className="cls-1"
                  x="-2.69" y="9.31"
                  width="28" height="4"
                  transform="translate(-4.69 11.31) rotate(-45)"/>
            <rect
                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"
                height="28" transform="translate(-16.69 16.28) rotate(-45)"/>
          </g>
        </g>
      </svg>
    </button>
);

const NextButton = (props) => (
    <button className="portfolio-slider__nav portfolio-slider__nav--next" onClick={props.onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6">
        <defs></defs>
        <g
            id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <rect id="Rechteck_26"
                  data-name="Rechteck 26"
                  className="cls-1"
                  x="-2.69" y="9.31"
                  width="28" height="4"
                  transform="translate(-4.69 11.31) rotate(-45)"/>
            <rect
                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"
                height="28" transform="translate(-16.69 16.28) rotate(-45)"/>
          </g>
        </g>
      </svg>
    </button>
);

const oldProjects = [
  {
    name: 'contento',
    assetPath: '/assets/02_pictures/00_projects/01_web/04_Contento/00_contento_teaser.jpg',
  },
  {
    name: 'puma',
    assetPath: '/assets/02_pictures/00_projects/01_web/01_Puma/00_puma_teaser.jpg',
  },
  {
    name: 'schottel',
    assetPath: '/assets/02_pictures/00_projects/01_web/02_Schottel/00_schottel_teaser.jpg',
  },
  {
    name: 'stihl',
    assetPath: '/assets/02_pictures/00_projects/01_web/03_Stihl/00_stihl_teaser.jpg',
  },
];

const projects = [
  {
    id: 1,
    title: 'Puma',
    subtitle: 'Geschäftsbericht',
    description: 'Puma Geschäftsbericht',
    image: '/assets/02_pictures/00_projects/01_web/01_Puma/00_puma_teaser.jpg',
    tags: ['WEB LAUNCH', 'GESCHÄFTSBERICHT'],
    link: '/project/puma',
  },
  {
    id: 2,
    title: 'Schottel',
    subtitle: 'Relaunch',
    description: 'Schottel Relaunch',
    image: '/assets/02_pictures/00_projects/01_web/02_Schottel/00_schottel_teaser.jpg',
    tags: ['WEB RELAUNCH', 'WEBSITE'],
    link: '/project/schottel',
  },
  {
    id: 3,
    title: 'Stihl',
    subtitle: 'Relaunch',
    description: 'Stihl Relaunch',
    image: '/assets/02_pictures/00_projects/01_web/03_Stihl/00_stihl_teaser.jpg',
    tags: ['WEB RELAUNCH', 'WEBSITE'],
    link: '/project/stihl',
  },
  // Add more projects as needed
];

export default function ProjectSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    centerMode: true,
    infinite: false,
    centerPadding: '25%',
    slidesToShow: 1,
    dots: true,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '0%',
        },
      },
      {
        breakpoint: 1500,
        settings: {
          centerPadding: '5%',
        },
      },
      {
        breakpoint: 2400,
        settings: {
          centerPadding: '15%',
        },
      },

    ],
    beforeChange: (_, next) => {
      setCurrentSlide(next);
    },
    customPaging: i => (
        <div
            className={`portfolio-slider__dot ${i === currentSlide ? 'active' : ''}`}
        />
    ),
    prevArrow: <PrevButton/>,
    nextArrow: <NextButton/>,
  };

  // const [isAnimating, setIsAnimating] = useState(false);
  //
  // const nextSlide = () => {
  //   if (!isAnimating) {
  //     setIsAnimating(true);
  //     setCurrentSlide((prev) => (prev + 1) % projects.length);
  //   }
  // };
  //
  // const prevSlide = () => {
  //   if (!isAnimating) {
  //     setIsAnimating(true);
  //     setCurrentSlide((prev) => (prev - 1 + projects.length) % projects.length);
  //   }
  // };
  //
  // const goToSlide = (index) => {
  //   if (!isAnimating && index !== currentSlide) {
  //     setIsAnimating(true);
  //     setCurrentSlide(index);
  //   }
  // };
  //
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsAnimating(false);
  //   }, 500);
  //
  //   return () => clearTimeout(timer);
  // }, [currentSlide]);

  return (
      <div className="portfolio-slider">
        <div className="portfolio-slider__header">
          <h1 className="portfolio-slider__title">So vielfältig wie einzigartig.</h1>
          <h2 className="portfolio-slider__subtitle">Unser Kunden in Szene gesetzt.</h2>
        </div>

        <Slider {...settings} >
          {projects.map((project, index) => (
              <div>
                <div
                    key={project.id}
                    className={`portfolio-slider__slide ${index === currentSlide ? 'active' : ''}`}
                >
                  <div className="portfolio-slider__image-container">
                    <img src={project.image} alt={project.title} className="portfolio-slider__image"/>
                  </div>
                  <div className="portfolio-slider__info">
                    <h3 className="portfolio-slider__project-title">{project.title}</h3>
                    <h4 className="portfolio-slider__project-subtitle">{project.subtitle}</h4>
                    <div className="portfolio-slider__tags">
                      {project.tags.map((tag) => (
                          <span key={tag} className="portfolio-slider__tag">
                        {tag}
                      </span>
                      ))}
                    </div>
                    <p className="portfolio-slider__description">{project.description}</p>
                    <a href={project.link} className="portfolio-slider__link">
                      mehr erfahren
                    </a>
                  </div>
                </div>
              </div>
          ))}
        </Slider>

        {/*<div className="portfolio-slider__container">*/}
        {/*  <button className="portfolio-slider__nav portfolio-slider__nav--prev" onClick={prevSlide}>*/}
        {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6">*/}
        {/*      <defs></defs>*/}
        {/*      <g*/}
        {/*          id="Ebene_2" data-name="Ebene 2">*/}
        {/*        <g id="Ebene_1-2" data-name="Ebene 1">*/}
        {/*          <rect id="Rechteck_26"*/}
        {/*                data-name="Rechteck 26"*/}
        {/*                className="cls-1"*/}
        {/*                x="-2.69" y="9.31"*/}
        {/*                width="28" height="4"*/}
        {/*                transform="translate(-4.69 11.31) rotate(-45)"/>*/}
        {/*          <rect*/}
        {/*              id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"*/}
        {/*              height="28" transform="translate(-16.69 16.28) rotate(-45)"/>*/}
        {/*        </g>*/}
        {/*      </g>*/}
        {/*    </svg>*/}
        {/*  </button>*/}

        {/*  <div className="portfolio-slider__content" style={{overflow: 'visible'}}>*/}
        {/*    {projects.map((project, index) => (*/}
        {/*        <div*/}
        {/*            key={project.id}*/}
        {/*            className={`portfolio-slider__slide ${index === currentSlide ? 'active' : ''}`}*/}
        {/*            style={{transform: `translateX(${(index - currentSlide) * 100}%)`}}*/}
        {/*        >*/}
        {/*          <div className="portfolio-slider__image-container">*/}
        {/*            <img src={project.image} alt={project.title} className="portfolio-slider__image"/>*/}
        {/*          </div>*/}
        {/*          <div className="portfolio-slider__info">*/}
        {/*            <h3 className="portfolio-slider__project-title">{project.title}</h3>*/}
        {/*            <h4 className="portfolio-slider__project-subtitle">{project.subtitle}</h4>*/}
        {/*            <div className="portfolio-slider__tags">*/}
        {/*              {project.tags.map((tag) => (*/}
        {/*                  <span key={tag} className="portfolio-slider__tag">*/}
        {/*              {tag}*/}
        {/*            </span>*/}
        {/*              ))}*/}
        {/*            </div>*/}
        {/*            <p className="portfolio-slider__description">{project.description}</p>*/}
        {/*            <a href={project.link} className="portfolio-slider__link">*/}
        {/*              mehr erfahren*/}
        {/*            </a>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}

        {/*<button className="portfolio-slider__nav portfolio-slider__nav--next" onClick={nextSlide}>*/}
        {/*  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6">*/}
        {/*    <defs></defs>*/}
        {/*    <g*/}
        {/*        id="Ebene_2" data-name="Ebene 2">*/}
        {/*      <g id="Ebene_1-2" data-name="Ebene 1">*/}
        {/*        <rect id="Rechteck_26"*/}
        {/*              data-name="Rechteck 26"*/}
        {/*              className="cls-1"*/}
        {/*              x="-2.69" y="9.31"*/}
        {/*              width="28" height="4"*/}
        {/*              transform="translate(-4.69 11.31) rotate(-45)"/>*/}
        {/*        <rect*/}
        {/*            id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"*/}
        {/*            height="28" transform="translate(-16.69 16.28) rotate(-45)"/>*/}
        {/*      </g>*/}
        {/*    </g>*/}
        {/*  </svg>*/}
        {/*</button>*/}
        {/*</div>*/}

        {/*<div className="portfolio-slider__dots">*/}
        {/*  {projects.map((_, index) => (*/}
        {/*      <button*/}
        {/*          key={index}*/}
        {/*          className={`portfolio-slider__dot ${index === currentSlide ? 'active' : ''}`}*/}
        {/*          onClick={() => goToSlide(index)}*/}
        {/*      />*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
  );
}
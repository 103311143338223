import React from "react";
import Navbar from "../components/element/Navbar";
import Footer from "../components/module/Footer";

function Impressum() {
  return (
    <div className="Impressum">
      <div className="App">
        <Navbar className="with-background" />
        <div className="main">
          <div className="section walloftext">
            <h2>Impressum</h2>
            <p>
              Marvin Jerome Jansen
              <br />
              Rheinstra&szlig;e 82
              <br />
              65185 Wiesbaden
            </p>
            <h3>Kontakt</h3>
            <p>
              Telefon: +49 176 8314 5513
              <br />
              E-Mail: kontakt@fallerjansen.de
            </p>
            <h3>EU-Streitschlichtung</h3>
            <p>
              Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
              <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
                https://ec.europa.eu/consumers/odr/
              </a>
              .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3>
            <p>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <p>
              Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Impressum;
